import denominate from "components/Denominate/denominate";
import { denomination } from "./../config";
import { StateType, TransactionType, EconomicsType } from "./state";

export type ActionType = {
  type: "setTransactions" | "setEconomics";
  transactions?: TransactionType[];
  transactionsFetched?: StateType["transactionsFetched"];
  economics?: EconomicsType | undefined;
  economicsFetched?: StateType["economicsFetched"];
};

export function reducer(state: StateType, action: ActionType): StateType {
  switch (action.type) {
    case "setTransactions": {
      const { economics } = state;
      const price = economics?.price || 0;
      const transactions = (action.transactions as TransactionType[]).map(
        (tx) => {
          const feeTax =
            Number(
              denominate({
                input: tx.fee as string,
                denomination: denomination,
                decimals: 6,
                showLastNonZeroDecimal: false,
                addCommas: false,
              }),
            ) * price;
          tx.feeTax = feeTax;
          return tx;
        },
      );
      const newState: StateType = {
        ...state,
        transactions: transactions,
        transactionsFetched: action.transactionsFetched,
      };
      return newState;
    }
    case "setEconomics": {
      const newState: StateType = {
        ...state,
        economics: action.economics,
        economicsFetched: action.economicsFetched,
      };
      return newState;
    }
    default: {
      throw new Error(`Unhandled action type: ${action?.type}`);
    }
  }
}
