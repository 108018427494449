export interface EconomicsType {
  apr: number;
  baseApr: number;
  circulatingSupply: number;
  marketCap: number;
  price: number;
  staked: number;
  topUpApr: number;
  totalSupply: number;
}

interface ScResultType {
  callType: string;
  gasLimit: number;
  gasPrice: number;
  nonce: number;
  prevTxHash: string;
  receiver?: string;
  sender: string;
  value: string;
  data?: string;
  returnMessage?: string;
}

type TxStatusType = "pending" | "notExecuted" | "success" | "fail";

export interface TransactionType {
  fee?: string;
  blockHash: string;
  data: string;
  gasLimit: number;
  gasPrice: number;
  gasUsed: string;
  txHash: string;
  miniBlockHash: string;
  nonce: number;
  receiver: string;
  receiverShard: number;
  round: number;
  sender: string;
  senderShard: number;
  signature: string;
  status: TxStatusType;
  timestamp: number;
  value: string;
  scResults?: ScResultType[];
  feeTax?: number;
}

export interface StateType {
  transactions: TransactionType[];
  transactionsFetched: boolean | undefined;
  economics: EconomicsType | undefined;
  economicsFetched: boolean | undefined;
  transactionCount: number;
}

const initialState = (): StateType => {
  return {
    transactions: [],
    transactionsFetched: undefined,
    economics: undefined,
    economicsFetched: undefined,
    transactionCount: 0,
  };
};

export default initialState;
