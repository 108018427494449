import * as React from "react";
import * as Dapp from "@elrondnetwork/dapp";
import { useContext } from "context";
import Denominate from "./../../components/Denominate";

const TopInfo = () => {
  const {
    address,
    account: { balance },
  } = Dapp.useContext();
  const { economics } = useContext();
  const Price = economics?.price || 0;

  return (
    <div className="text-white" data-testid="topInfo">
      <div className="mb-1">
        <span className="opacity-6 mr-1">Your address:</span>
        <span data-testid="accountAddress"> {address}</span>
      </div>
      <div className="mb-1">
        <span className="opacity-6 mr-1">Egld Cost:</span>
        <span data-testid="accountAddress"> {Price}</span>
      </div>
      <div>
        <h3 className="py-2">
          <Denominate value={balance} dataTestId="balance" decimals={6} />
        </h3>
      </div>
    </div>
  );
};

export default TopInfo;
