import axios from "axios";

interface GetEconomics {
  apiAddress: string;
  timeout: number;
  page?: number;
  url?: string;
}
interface GetLatestTransactionsType {
  apiAddress: string;
  address: string;
  timeout: number;
  page?: number;
  pageSize?: number;
  url?: string;
}

const fetchTransactions = (url: string) =>
  async function getTransactions({
    apiAddress,
    address,
    timeout,
    pageSize,
  }: GetLatestTransactionsType) {
    try {
      const { data } = await axios.get(`${apiAddress}${url}`, {
        params: {
          sender: address,
          size: pageSize || 50,
        },
        timeout,
      });

      return {
        data: data,
        success: data !== undefined,
      };
    } catch (err) {
      return {
        success: false,
      };
    }
  };

const fetchEconomics = () =>
  async function getData({ apiAddress, timeout }: GetEconomics) {
    try {
      const { data } = await axios.get(`${apiAddress}/economics`, {
        params: {},
        timeout,
      });

      return {
        data: data,
        success: data !== undefined,
      };
    } catch (err) {
      return {
        data: 0,
        success: false,
      };
    }
  };

export const getTransactions = fetchTransactions("/transactions");
export const getTransactionsCount = fetchTransactions("/transactions/count");

export const getEconomics = fetchEconomics();
