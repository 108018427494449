import React from "react";
import * as Dapp from "@elrondnetwork/dapp";
import { Ui } from "@elrondnetwork/dapp-utils";
import moment from "moment";
import Denominate from "components/Denominate";
import { TransactionType } from "context/state";
import StatusIcon from "./StatusIcon";
import txStatus from "./txStatus";

function sortByDate(a: TransactionType, b: TransactionType) {
  if (a.timestamp < b.timestamp) {
    return 1;
  }
  if (a.timestamp > b.timestamp) {
    return -1;
  }
  return 0;
}

const TransactionList = ({
  transactions,
}: {
  transactions: TransactionType[];
}) => {
  const { address, explorerAddress } = Dapp.useContext();
  const incoming = (sender: string) => sender === address;
  const sortedTransactions: TransactionType[] = (
    transactions.filter((el: any) => el !== undefined) as any
  ).sort(sortByDate);
  const feeTaxCost = sortedTransactions.reduce(
    (prev, tx) => prev + (tx.feeTax ? tx.feeTax : 0),
    0,
  );

  return (
    <div className="p-3 mt-3">
      <h4 className="mb-3 font-weight-normal">
        Transactions Fee Costs {feeTaxCost}$
      </h4>
      <div className="table-responsive">
        <table className="transactions table pb-3">
          <thead>
            <tr className="bg-light">
              <th className="border-0 font-weight-normal"></th>
              <th className="border-0 font-weight-normal">Tx hash</th>
              <th className="border-0 font-weight-normal">Date</th>
              <th className="border-0 font-weight-normal">Amount</th>
              <th className="border-0 font-weight-normal">Fees EGLD</th>
              <th className="border-0 font-weight-normal">Fees $</th>
            </tr>
          </thead>
          <tbody data-testid="transactionsList">
            {sortedTransactions.map((tx: TransactionType, i) => {
              const incomingTransaction = incoming(tx.sender);
              return (
                <tr key={tx.txHash + i}>
                  <td>
                    <div
                      className="transaction-icon bg-light d-flex align-items-center justify-content-center"
                      title={
                        txStatus[tx.status] +
                        " " +
                        (incomingTransaction ? "In" : "Out")
                      }
                    >
                      <StatusIcon
                        tx={tx}
                        incomingTransaction={incomingTransaction}
                      />
                    </div>
                  </td>
                  <td className="transaction-hash">
                    <a
                      href={`${explorerAddress}transactions/${tx.txHash}`}
                      {...{
                        target: "_blank",
                      }}
                      title="View in Explorer"
                    >
                      <Ui.Trim data-testid="txHash" text={tx.txHash} />
                    </a>
                  </td>
                  <td className="transaction-date">
                    {moment.unix(tx.timestamp).format("MMM Do YY, h:mm A")}
                  </td>
                  <td>
                    {tx.value === "0" ? (
                      ""
                    ) : (
                      <>{tx.sender === address ? "-" : "+"}</>
                    )}
                    <Denominate value={tx.value} decimals={6} />
                  </td>
                  <td>
                    {tx.fee === "0" ? "" : "-"}
                    <Denominate value={tx.fee as string} decimals={6} />
                  </td>
                  <td>{tx.feeTax}$</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-center">
        <a
          href={`${explorerAddress}address/${address}`}
          {...{
            target: "_blank",
          }}
        >
          See all transactions
        </a>
      </div>
    </div>
  );
};

export default TransactionList;
